module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- footer -->\r\n<section class="root-footer">\r\n    <div class="container-fluid">\r\n        <div class="backTop">\r\n            <div class="backTop-cont">\r\n                <div class="icon"><i class="iconfont icon-shangjiantou"></i></div>\r\n                <div class="text">TOP</div>\r\n            </div>\r\n        </div>\r\n        <div class="footer-cont">\r\n            <div class="cont1">\r\n                <i class="iconfont icon-weixin"></i>\r\n                <div class="text">官方微信公众号<br>扫一扫了解更多信息</div>\r\n                <div class="wx-img"><img src="' +
((__t = (require('./images/wx_ewm.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n            </div>\r\n            <div class="cont2"><img src="' +
((__t = (require('./images/bottom_logo.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n            <div class="cont3">\r\n                集团总部地址：青岛市市南区南海路23号<a href="tel:+ 053282899999"> 0532-82899999</a>\r\n            </div>\r\n        </div>\r\n        <div class="copyright">\r\n            <div class="cont1"><span>古天乐代言太阳集团网址</span></div>\r\n            <div class="cont2">© 2020 青岛印象版权所有 QUCG. All Rights Reserved. <a href="#">鲁ICP备05018705号</a></div>\r\n            <div class="cont3">\r\n                <a href="#">隐私条款</a>\r\n                <a href="#">版权声明</a>\r\n                <a href="javascript:void(0);" data-open="map">网站地图</a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</section>\r\n';

}
return __p
}